import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const NotFoundPage = () => (
  <Layout>
    <SEO title="top secret test page uwu" />
    <a href="https://www.patreon.com/oauth2/authorize?response_type=code&client_id=RuFwy6W5vmz7r7yCNeso21o1pWuU7nIbUklrKMG7BPksrv05fnh13eZMe-3BPEQv&redirect_uri=https://www.horny.moe">authorize</a>
  </Layout>
);

export default NotFoundPage;
